#mondrian {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.979),
      rgba(255, 255, 255, 0.514),
      rgba(255, 255, 255, 0.103)
    ),
    /* url("./monrain.jpeg"); */
      url("https://images.pexels.com/photos/5945859/pexels-photo-5945859.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260");
  background-size: cover;
  margin: 0 auto;
  box-sizing: content-box;
}
input {
  /* font-weight: bold; */
  font-family: sans-serif;
  text-transform: uppercase;
  font-family: "Waiting for the Sunrise";
  /* color: var(--colorMain); */
}

#form-bg {
  box-shadow: inset -10px 14px 21px -4px rgba(255, 255, 255, 0.753),
    inset 17px -22px 21px -4px rgba(0, 0, 0, 0.46),
    1px 16px 29px 9px rgba(0, 0, 0, 0.932);
  color: var(--colorMain);
  background: var(--colorMain);
  text-transform: uppercase;
  text-shadow: var(--brShadow), var(--tlShadow);
  text-align: center;
  opacity: 0.85;
  width: 30vw;
  padding: 1em;
  border-radius: 25px;

  margin-bottom: 5em;
  margin-top: 15vh;
  font-weight: bold;
  font-family: sans-serif;

  position: relative;

  -webkit-text-stroke-width: 1px;
  /* -webkit-text-stroke-color: rgb(184, 181, 181); */
}
@media only screen and (max-width: 1160px) {
  #form-bg {
    width: 80vw;

    margin-bottom: 1em;
    /* height: 30vh; */
  }
}
:root {
  --colorMain: #c0c0c0;
  --brShadow: -3px 3px 7px rgba(0, 0, 0, 0.938);
  --tlShadow: 2.5px -2.5px 8px rgb(255, 255, 255);
}
.completed {
  text-decoration: line-through;
}
/* 
.glow-on-hover:active {
  color: #000;
  opacity: 0.5;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 0.5;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}  */

.tilesWrap {
  padding: 0;
  margin: 0 auto;
  list-style: none;
  text-align: center;
  margin-left: 2em;
  margin-top: 2vh;
}
@media only screen and (min-width: 1160px) {
  .tilesWrap {
    width: 60vw;
    margin-left: 0em;
    margin-top: 15vh;
  }
}

@media only screen and (max-width: 1160px) {
  .tilesWrap {
    width: 100vw;
  }
}
.tilesWrap li {
  vertical-align: top;
  margin: 10px;
  font-family: "helvetica", san-serif;

  box-shadow: 1px 18px 15px -4px #00000081;
  text-align: left;
}
.tilesWrap li h2 {
  font-size: 3em;
  margin: 0;
  color: black;
  opacity: 0.5;
  top: 20px;
  right: 10px;
  transition: all 0.3s ease-in-out;
}
/* .tilesWrap li h3 {
  font-size: 20px;
  color: #b7b7b7;
  margin-bottom: 5px;
}
.tilesWrap li p {
  font-size: 16px;
  line-height: 18px;
  color: #b7b7b7;
  margin-top: 5px;
} */
.tilesWrap li button {
  background: transparent;
  border: 1px solid #474747;

  color: #474747;
  border-radius: 3px;
  position: relative;
  transition: all 0.3s ease-in-out;
  /* transform: translateY(-40px); */
  opacity: 0.5;
  cursor: pointer;
  overflow: hidden;
  margin-left: 10px;
}
.tilesWrap li button:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 120%;
  background: #b7b7b7;
  top: 0;
  opacity: 0;
  left: -140px;
  border-radius: 0 20px 20px 0;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}
.tilesWrap li:hover button {
  transform: translateY(-15px);
  opacity: 1;
}
.tilesWrap li button:hover {
  color: #262a2b;
}
.tilesWrap li button:hover:before {
  left: 0;
  opacity: 1;
}
.tilesWrap li:hover h2 {
  top: 0px;
  opacity: 0.6;
}

/* #box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Raleway";
  font-size: 2.5rem;
} */
.gradient-border {
  background: #53535381;
}

.MuiSvgIcon-root,
.MuiSelect-icon {
  /* position: fixed;
  left: 6vw; */
}
/* 
.button1 {
  background-color: #eb8a3b00;
  display: inline-block;
  padding: 0.35em 1.2em;
  border: 0.1em solid #ffffff;
  margin: 1em 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  font-weight: 300;

  text-align: center;
  transition: all 0.2s;
}
.button1:hover {
  color: #000000;
  background-color: #e2eff8e7;
}
@media all and (max-width: 30em) {
   a.button1 {
    display: block;
    margin: 0.4em auto;
  }
} */
.select-css {
  /* display: block; */
  /* font-size: 16px; */
  /* font-family: sans-serif; */
  /* font-weight: 500; */
  /* color: rgb(199, 33, 33); */
  /* line-height: 1.3; */
  /* text-align: center; */
  /* padding: 0em 0em 0em 0.3em; */
  width: 80%;
  /* max-width: 100%; */
  box-sizing: border-box;
  margin: 2em;
  /* border: 1px solid #aaa; */
  box-shadow: none !important;
  border-radius: 0.5em;
  /* -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none; */
  background-color: rgb(255, 242, 242);
}
