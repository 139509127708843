/* html {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  position: relative;
  height: 100%;
} */

* {
  outline-color: transparent;
  /* border: none; */
  scroll-behavior: smooth;
}
/* div:not(.overlay):not(#home):not(#title) {
  filter: invert(1);
} */
/* hr{
  color:white;
} */
